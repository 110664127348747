import React from 'react'

import { postSim } from '../../common/utils'
import { useLogin } from '../../common/LoginContext'

import { useMembers } from '../../contexts/MembersContext'
import { useLocation } from 'react-router-dom'
import { useGroups } from '../../contexts/GroupContext'
import { encodeLessonUnlockCode, decodeLessonUnlockCode, generateDefaultMissionPackIdArray } from './lesson-unlock-code-operations'


const LessonUnlockerStateContext = React.createContext()
function LessonUnlockerProvider({ children }) {
  const { selectedGroupMissionPack, selectedGroupId } = useGroups()
  const { members, updateMember, updateMembers, selectedMemberId } = useMembers()
  const [loginState] = useLogin()
  const location = useLocation()
  const [userList, setUserList] = React.useState([])
  const [selectedListUsers, setSelectedListUsers] = React.useState([])
  const [selectedUserUnlocker, setSelectedUserUnlocker] = React.useState(null)
  const [selectedMissionPackIds, setSelectedMissionPackIds] = React.useState([])
  const [postingLessonUnlockCodeState, setPostingLessonUnlockCodeState] = React.useState(null)
  const [detailSelectionDialogOpen, setDetailSelectionDialogOpen] = React.useState(false)
  const [changesMadeToSelectedMissionPackIds, setChangesMadeToSelectedMissionPackIds] = React.useState(false)
  const selectableLessonItems = React.useRef([])
  const singleUserMode = location.pathname.includes('member')

  const selectedUserUnlockerMember = userList.find(x => x.userId === selectedUserUnlocker)
  const allUserListSelected = userList.length === selectedListUsers.length
  const allLessonItemsSelected = selectableLessonItems.current.length === selectedMissionPackIds.length

  React.useEffect(() => {
    function initializeGroupLessonUnlocker() {
      const sortedMembers = [...members].sort((a, b) => a.email.localeCompare(b.email))
      setUserList(sortedMembers)

      if (singleUserMode) {
        updateSelectedUserUnlocker(selectedMemberId)
      } else if (!members.find(member => member.id === selectedUserUnlocker)){
        updateSelectedUserUnlocker(sortedMembers[0].userId)
      }

      setSelectedListUsers(members.map(x => x.userId))
    }

    initializeGroupLessonUnlocker()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members])

  React.useEffect(() => {
    selectableLessonItems.current = generateDefaultMissionPackIdArray(selectedGroupMissionPack)
  }, [selectedGroupMissionPack])

  function toggleUserListSelection(userId) {
    setSelectedListUsers((z) => {
      const x = [...z]
      const index = x.indexOf(userId)
      if (index === -1) {
        x.push(userId)
      } else {
        x.splice(index, 1)
      }

      return x
    })
  }

  function toggleAllUserListSelection() {
    setSelectedListUsers((x) => {
      if (allUserListSelected) {
        return []
      }

      return userList.map(x => x.userId)
    })
  }

  function handleSettingMemberUnlockedLessonIds(lessonUnlockCode) {
    let selectedMissionIds
    if (lessonUnlockCode) {
      selectedMissionIds = decodeLessonUnlockCode(selectedGroupMissionPack, lessonUnlockCode)
    } else {
      selectedMissionIds = []
    }

    setSelectedMissionPackIds(selectedMissionIds)
  }

  function selectAllLessonItems() {
    setSelectedMissionPackIds(selectableLessonItems.current)
  }

  function deselectAllLessonItems() {
    setSelectedMissionPackIds([])
  }

  function discardLessonItemChanges() {
    handleSettingMemberUnlockedLessonIds(selectedUserUnlockerMember.lessonUnlockCode)
  }

  const memberUnlockCode = selectedUserUnlockerMember?.lessonUnlockCode
  React.useEffect(() => {
    const lessonUnlockCode = encodeLessonUnlockCode(selectedGroupMissionPack, selectedMissionPackIds)
    if (lessonUnlockCode !== memberUnlockCode) {
      setChangesMadeToSelectedMissionPackIds(true)
    } else {
      setChangesMadeToSelectedMissionPackIds(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserUnlockerMember?.lessonUnlockCode, selectedMissionPackIds])

  function updateSelectedUserUnlocker(userId) {
    setSelectedUserUnlocker(userId)
    const newSelectedMember = members.find(x => x.userId === userId)
    if (!newSelectedMember) {
      return
    }

    handleSettingMemberUnlockedLessonIds(newSelectedMember.lessonUnlockCode)
  }

  function toggleSelectedLessonContentId(id) {
    toggleMultipleSelectedLessonContentIds([id])
  }

  function toggleMultipleSelectedLessonContentIds(idArr) {
    setSelectedMissionPackIds((z) => {
      var x = [...z]
      for (const id of idArr) {
        const idx = x.indexOf(id)
        if (idx === -1) {
          x = [...x, id]
        } else {
          x = x.filter(y => y !== id)
        }
      }
      return x
    })
  }

  function toggleSelectedMissionContentRow(missionId) {
    const mission = selectedGroupMissionPack.missions.find(x => x.id === missionId)
    toggleMultipleSelectedLessonContentIds([missionId, ...mission.flow.map(x => x.id)])
  }

  async function postLessonUnlockCodes(memberIds) {
    const lessonUnlockCode = encodeLessonUnlockCode(selectedGroupMissionPack, selectedMissionPackIds)
    return await postSim(`groups/${selectedGroupId}/lessonUnlockCodes`, loginState?.user, {
      lessonUnlockCode,
      memberIds,
    })
  }

  async function postMultipleLessonUnlockCodes() {
    return await postLessonUnlockCodes(selectedListUsers)
  }

  async function postSingleLessonUnlockCode() {
    return await postLessonUnlockCodes([selectedUserUnlocker])
  }

  function onMultipleLessonUnlockCodesSuccess() {
    updateMembers(selectedListUsers.map(x => [x, { lessonUnlockCode: encodeLessonUnlockCode(selectedGroupMissionPack, selectedMissionPackIds) }]))
  }

  function onSingleLessonUnlockCodesSuccess() {
    console.log([selectedUserUnlocker, encodeLessonUnlockCode(selectedGroupMissionPack, selectedMissionPackIds)])
    updateMember(selectedUserUnlocker, { lessonUnlockCode: encodeLessonUnlockCode(selectedGroupMissionPack, selectedMissionPackIds) })
  }

  function toggleDetailSelectionDialogOpen() {
    setDetailSelectionDialogOpen(x => !x)
  }


  return (
    <LessonUnlockerStateContext.Provider
      value={{
        userList,
        selectedListUsers,
        toggleUserListSelection,
        toggleAllUserListSelection,
        updateSelectedUserUnlocker,
        toggleSelectedLessonContentId,
        toggleSelectedMissionContentRow,
        selectedUserUnlocker,
        selectedMissionPackIds,
        selectedUserUnlockerMember,
        allUserListSelected,
        allLessonItemsSelected,
        postingLessonUnlockCodeState,
        setPostingLessonUnlockCodeState,
        detailSelectionDialogOpen,
        toggleDetailSelectionDialogOpen,
        postMultipleLessonUnlockCodes,
        postSingleLessonUnlockCode,
        onMultipleLessonUnlockCodesSuccess,
        onSingleLessonUnlockCodesSuccess,
        selectAllLessonItems,
        deselectAllLessonItems,
        discardLessonItemChanges,
        singleUserMode,
        changesMadeToSelectedMissionPackIds,
      }}>
      {children}
    </LessonUnlockerStateContext.Provider>
  )
}

function useLessonUnlocker() {
  return React.useContext(LessonUnlockerStateContext)
}

export { LessonUnlockerProvider, useLessonUnlocker }