import React from 'react'
import {
  createMemoryRouter,
} from 'react-router-dom'

import { Root } from './Root'
import { GroupsOverviewPage } from '../pages/groups-overview/GroupsOverviewPage'
import { GroupPage } from '../pages/group/GroupPage'
import { MemberPage } from '../pages/member/MemberPage'
import { LessonUnlockControlPage } from '../pages/lesson-unlock-control/LessonUnlockControlPage'

const router = createMemoryRouter([
  {
    path: '/',
    element: <Root />,
    handle: 'root',
    children: [
      {
        index: true,
        element: <GroupsOverviewPage />,
      },
      {
        path: 'group/:groupId',
        element: <GroupPage />,
        handle: 'group',
      },
      {
        path: 'group/:groupId/member/:memberId',
        element: <MemberPage />,
        handle: 'member',
      },
      {
        path: 'group/:groupId/lesson-unlock-control',
        element: <LessonUnlockControlPage />,
        handle: 'lesson-unlock-control',
      },
      {
        path: 'group/:groupId/member/:memberId/lesson-unlock-control',
        element: <LessonUnlockControlPage />,
        handle: 'lesson-unlock-control',
      },
    ],
  },
])

export { router }