import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, MenuItem, Typography, Checkbox, Avatar, Grid } from '@material-ui/core'

import { OutlinedSelect } from '../../common/fields'
import { RespButton } from '../../common/buttons'
import { lessonContentDomain, AVATAR_SOURCE } from '../../common/utils'

import PanelTemplate from '../shared-components/PanelTemplate'
import { useGroups } from '../../contexts/GroupContext'
import { useSnackbar } from 'notistack'
import { LessonUnlockerProvider, useLessonUnlocker } from './LessonUnlockerContext'
import { UserSelectionDialog } from './UserSelectionDialog'
import { displayLessonUnlockerInfoSnackbar } from './display-lesson-unlocker-info-snackbar'


const useStyles = makeStyles((theme) => {
  return {
    mainPanelContent: {
      width: '100%',
      height: '100%',
      display: 'flex',
      backgroundColor: 'rgba(0,0,0,0.2)',
      borderTop: '1px solid black',
    },
    mainPanelContainer: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.primary.main,
    },
    bodyContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      padding: '10px 20px',
    },
    bodyContent: {
      backgroundColor: theme.palette.primary.main,
      border: '1px solid rgba(0,0,0,0.4)',
      minWidth: 900,
      width: '50%',
    },
    detailContainer: {
      backgroundColor: 'white',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    footerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent:'flex-end',
      backgroundColor: 'rgba(0,0,0,0.05)',
      borderTop: '1px solid rgba(0,0,0,0.1)',
      padding: '10px 20px',
      height: 60,
      gap: 10,
    },
    rowButtonContainer: {
      height: '100%',
      width: '100%',
    },
    rowButton: {
      textTransform: 'none',
      borderRadius: 3,
      display: 'flex',
      justifyContent: 'flex-start',
      border: '1px solid rgba(0,0,0,0.2)',
      width: '100%',
      height: '100%',
      padding: 0,
    },
    avatar: {
      borderRadius: 3,
      height: 50,
      width: 50,
      margin: '3px 6px',
    },
    rowTextContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    gridContainer: {
      height: 'calc(100% - 66px - 60px)',
      overflowY: 'overlay',
      overflowX: 'hidden',
    },
    grid: {
      padding: '10px 20px',
    },
    detailHeaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'rgba(0,0,0,0.05)',
      borderBottom: '1px solid rgba(0,0,0,0.07)',
      padding: '10px 20px',
    },
    detailHeaderUserSelectContainer:{
      borderRadius: 3,
      backgroundColor: 'white',
      width: 'calc(50% - 8px)',
    },
    missionListSelectorContainer: {
      display: 'flex',
      alignItems:'center',
      gap: 10,
    },
  }
})

function MissionListSelectorButtons() {
  const classes = useStyles()
  const {
    allLessonItemsSelected,
    selectAllLessonItems,
    deselectAllLessonItems,
    changesMadeToSelectedMissionPackIds,
    discardLessonItemChanges,
  } = useLessonUnlocker()

  function onLessonSelectionClick() {
    if (allLessonItemsSelected) {
      deselectAllLessonItems()
    } else {
      selectAllLessonItems()
    }
  }

  return (
    <div className={classes.missionListSelectorContainer}>
      <div style={{backgroundColor: 'white'}}>
        <Button
          onClick={discardLessonItemChanges}
          disabled={!changesMadeToSelectedMissionPackIds}
          variant='contained'
          disableElevation
          style={{border: '1px solid rgba(0,0,0,0.2)'}}
        >
          {'Discard Changes'}
        </Button>
      </div>
      <div style={{backgroundColor: 'white'}}>
        <Button
          onClick={onLessonSelectionClick}
          variant='outlined'
          style={{width: 139}}
        >
          {allLessonItemsSelected ? 'Deselect All' : 'Select All'}
        </Button>
      </div>
    </div>
  )
}

function DetailHeaderUserSelect() {
  const { singleUserMode, selectedUserUnlocker, updateSelectedUserUnlocker, userList } = useLessonUnlocker()
  function onSelection(ev) {
    updateSelectedUserUnlocker(ev.target.value)
  }

  if (selectedUserUnlocker === null) {
    return null
  }

  return (
    <OutlinedSelect
      value={selectedUserUnlocker}
      onChange={onSelection}
      style={{width: '100%'}}
      disabled={singleUserMode}
    >
      {userList.map(user => (
        <MenuItem
          key={user.userId}
          value={user.id}>
          {user.email}
        </MenuItem>
      ))}
    </OutlinedSelect>
  )
}

function DetailHeader() {
  const classes = useStyles()
  return (
    <div className={classes.detailHeaderContainer}>
      <div className={classes.detailHeaderUserSelectContainer}>
        <DetailHeaderUserSelect />
      </div>
      <MissionListSelectorButtons />
    </div>
  )
}

function MissionRow({ id, avatar, title, idx }) {
  const classes = useStyles()
  const { selectedMissionPackIds, toggleSelectedMissionContentRow } = useLessonUnlocker()

  function handleClick() {
    toggleSelectedMissionContentRow(id)
  }

  const selected = selectedMissionPackIds.includes(id)
  return (
    <Grid
      item
      key={id}
      md={6}
      sm={6}
      xs={12}>
      <div
        className={classes.rowButtonContainer}
        style={{backgroundColor: selected ? 'white' : 'rgba(0,0,0,0.05)'}}
      >
        <Button
          key={id}
          disableRipple={true}
          onClick={handleClick}
          className={classes.rowButton}
        >
          <Avatar
            variant='square'
            src={lessonContentDomain + AVATAR_SOURCE + avatar}
            className={classes.avatar}
          />
          <Checkbox checked={selected}/>
          <div className={classes.rowTextContainer}>
            <Typography variant='caption'>{`Mission ${idx + 1}`}</Typography>
            <Typography style={{fontSize: 18}}>{`${title}`}</Typography>
          </div>
        </Button>
      </div>
    </Grid>
  )
}

function DetailBody() {
  const classes = useStyles()
  const { selectedGroupMissionPack } = useGroups()

  return (
    <div className={classes.gridContainer}>
      <Grid
        container
        spacing={1}
        alignContent='flex-start'
        className={classes.grid}
      >
        {selectedGroupMissionPack.missions.map((msn, idx) =>
          <MissionRow key={msn.id} idx={idx} {...msn} />
        )}
      </Grid>
    </div>
  )
}

function DetailFooter(){
  const classes = useStyles()
  const snacks = useSnackbar()
  const {
    toggleDetailSelectionDialogOpen,
    postSingleLessonUnlockCode,
    onSingleLessonUnlockCodesSuccess,
    postingLessonUnlockCodeState,
    setPostingLessonUnlockCodeState,
    changesMadeToSelectedMissionPackIds,
  } = useLessonUnlocker()

  function handleApplySingleLessonUnlockCodeChangesSuccess() {
    displayLessonUnlockerInfoSnackbar(snacks)
    snacks.enqueueSnackbar('Successfully applied changes.', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 2000,
    })
    onSingleLessonUnlockCodesSuccess()
  }

  return (
    <div className={classes.footerContainer}>
      <RespButton
        variant='contained'
        color='secondary'
        buttonComponent={Button}
        onClick={postSingleLessonUnlockCode}
        successCb={handleApplySingleLessonUnlockCodeChangesSuccess}
        status={postingLessonUnlockCodeState}
        setStatus={setPostingLessonUnlockCodeState}
        disabled={!changesMadeToSelectedMissionPackIds}
        style={{border: '1px solid rgba(0,0,0,0.2)'}}
        disableElevation
      >
        {'Apply Changes'}
      </RespButton>
      <div style={{backgroundColor: 'white'}}>
        <Button variant='outlined' onClick={toggleDetailSelectionDialogOpen}>{'Apply To Multiple Users'}</Button>
      </div>
    </div>
  )
}

function UnlockControlDetail() {
  const classes = useStyles()

  return (
    <div className={classes.detailContainer}>
      <DetailHeader />
      <DetailBody />
      <DetailFooter />
      <UserSelectionDialog />
    </div>
  )
}

function LessonUnlockControlPage() {
  const classes = useStyles()

  return (
    <LessonUnlockerProvider>
      <PanelTemplate
        mainPanelContent={
          <div className={classes.mainPanelContainer}>
            <div className={classes.mainPanelContent}>
              <div className={classes.bodyContainer}>
                <div className={classes.bodyContent}>
                  <UnlockControlDetail />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </LessonUnlockerProvider>
  )
}

export { LessonUnlockControlPage }