import React from 'react'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'


var infoMessageAlreadyDisplayed = false
function displayLessonUnlockerInfoSnackbar(snacks) {
  if (infoMessageAlreadyDisplayed) {
    return
  }

  infoMessageAlreadyDisplayed = true
  const key = snacks.enqueueSnackbar(
    <div>
      <div>
        {'Students who are logged in will need to refresh'}
      </div>
      <div>
        {'their browser for the changes to take effect.'}
      </div>
    </div>
    , {
      variant: 'info',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 20000,
      action: (
        <IconButton size='small' aria-label='close' color='inherit' onClick={() => snacks.closeSnackbar(key)}>
          <CloseIcon fontSize='small' />
        </IconButton>
      ),
    })
}

export { displayLessonUnlockerInfoSnackbar }