import React from 'react'

import { getSim, deleteSim } from '../common/utils'
import { useLogin } from '../common/LoginContext'
import { useMissionPacks } from '../common/MissionPackContext'

async function fetchGroups(user) {
  const resp = await getSim('groups/?role=owner', user)
  if (resp.status === 200){
    const data = (await resp.json())
    return {status: resp.status, data}
  }

  return { status: resp.status }
}

const GroupsStateContext = React.createContext()
function GroupsProvider({ children }) {
  const { missionPacks } = useMissionPacks()
  const [loginState] = useLogin()
  const [groups, setGroups] = React.useState([])
  const [groupsFetchStatus, setGroupsFetchStatus] = React.useState('loggedOut')
  const [selectedGroupId, setSelectedGroupId] = React.useState(null)

  async function getGroups() {
    setGroupsFetchStatus('waiting')
    const { status, data } = await fetchGroups(loginState?.user)
    if (status === 200){
      data.forEach((row, index) => {
        row.groupName = row.name
        row.groupId = row.id
      })

      setGroups(data)
      setGroupsFetchStatus('success')
    } else {
      setGroupsFetchStatus('error')
    }
  }

  function clearGroups() {
    setGroups([])
  }

  async function handleDeleteGroups(groupRowIds){
    const resp = await Promise.all(groupRowIds.map(groupId => deleteSim(`groups/${groupId}`, loginState?.user)))
    return resp.find(({status}) => status !== 200) ?? resp[0]
  }

  async function handleDeleteGroup(groupId){
    return handleDeleteGroups([groupId])
  }


  React.useEffect(() => {
    if (loginState?.user){
      getGroups()
    } else {
      setSelectedGroupId(null)
      clearGroups()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState])

  const selectedGroup = groups.find(group => group.groupId === selectedGroupId) ?? {}
  const selectedGroupMissionPack = missionPacks.find(pack => pack.id === selectedGroup.missionPackId) ?? {}
  return (
    <GroupsStateContext.Provider
      value={{
        groups,
        groupsFetchStatus,
        selectedGroupId,
        selectedGroup,
        selectedGroupMissionPack,
        setSelectedGroupId,
        getGroups,
        clearGroups,
        handleDeleteGroup,
        handleDeleteGroups,
      }}>
      {children}
    </GroupsStateContext.Provider>
  )
}

function useGroups() {
  return React.useContext(GroupsStateContext)
}

export {
  GroupsProvider,
  useGroups,
}
